import Img from "gatsby-image"
import React from "react"
import ButtonSm from "./ButtonSm"

const BlogCard = props => {
  const { title, slug, featuredImage, body, author, date } = props

  return (
    <a href={`/blog/${slug}`} className="lg:w-2/5 mx-2 mb-6 lg:mb-0">
      <div className="flex flex-col justify-around bg-white  shadow-sm overflow-hidden border border-1 border-gray-200">
        <Img fluid={featuredImage.fluid} alt={featuredImage.description} />
        <div className="p-4 sm:p-5">
          <h1 className="text-xl font-bold">{title}</h1>
          <span className="text-xs sm:text-base">{author} | {date}</span>
          <p className="text-sm sm:text-base pt-3">{body.childMarkdownRemark.excerpt}</p>
        </div>
        <div className="mb-4 ml-4">
          <ButtonSm href={`/blog/${slug}`}>Learn More</ButtonSm>
        </div>
      </div>
    </a> 
  )
}


export default BlogCard
