import React from "react"
import { graphql } from "gatsby"
import BlogCard from "../components/BlogCard";


//internal
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"



const TagIndexTemplate = ({ data, pageContext}) => {
  const { tag, pages } = pageContext
  console.log(pages)

  const posts = data.posts.edges

  const PostWrap = ({children}) => (
    <div className="container flex flex-row justify-evenly mx-auto w-screen" >{children}</div>

  )

  return (
    <Layout>
      <SiteMetadata title="Zurly Resourses" description={`Resoures tags ${tag}`} />
      <div className="bg-customlightgray">
        <h1 className="text-center pt-32 capitalize my-0">{`Resources for ${tag}`}</h1>
        <PostWrap>
          {posts.map(({ node }) => {
              return (
              <BlogCard
              title={node.title}
              featuredImage={node.featuredImage}
              link={node.slug} 
              date={node.createdAt}
              body={node.body}
              author={node.author}
              slug={node.slug}
              />
              )
          }
          )}
      </PostWrap>
    </div>

    </Layout>
  )
}

export default TagIndexTemplate

export const pageQuery = graphql`
  query CategoryPageQuery($tag: String) {
    posts: allContentfulBlogPost(filter: {tags: {eq: $tag}}) {
      edges {
        node {
          author
          title
          body {
            childMarkdownRemark {
              excerpt(pruneLength: 200)
              html
            }
          }
          createdAt(formatString: "MMMM DD, YYYY")
          featuredImage {
            description
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          slug
        }
      }
    }
  }
`

